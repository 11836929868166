<template>
  <section
    v-if="blog"
    class="n-section-primary tw-mt-20"
    :style="`background-color: var(--c-${vars.backgroundColor})`"
  >
    <div class="tw-flex tw-w-full tw-items-center tw-pb-6">
      <nuxt-link
        :external="true"
        :to="ROUTES.BLOGS"
        class="n-link tw-flex tw-items-center"
      >
        <div class="tw-mr-2">
          <svg
            width="28"
            height="17"
            viewBox="0 0 28 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M21.875 9.375H6.125C5.635 9.375 5.25 8.99 5.25 8.5C5.25 8.01 5.635 7.625 6.125 7.625H21.875C22.365 7.625 22.75 8.01 22.75 8.5C22.75 8.99 22.365 9.375 21.875 9.375Z"
              fill="var(--c-primary)"
            />
            <path
              d="M10.5 15.5C10.3852 15.5014 10.2715 15.4788 10.166 15.4336C10.0605 15.3883 9.96563 15.3216 9.8875 15.2375L3.7625 9.1125C3.4125 8.7625 3.4125 8.22 3.7625 7.87L9.8875 1.7625C10.2375 1.4125 10.78 1.4125 11.13 1.7625C11.48 2.1125 11.48 2.655 11.13 3.005L5.6175 8.5175L11.13 14.03C11.48 14.38 11.48 14.9225 11.13 15.2725C10.955 15.4475 10.7275 15.535 10.5175 15.535L10.5 15.5Z"
              fill="var(--c-primary)"
            />
          </svg>
        </div>
        <h6 style="color: var(--c-primary)">Back to All Blogs</h6>
      </nuxt-link>
    </div>
    <div class="tw-grid tw-grid-cols-12 tw-gap-4">
      <article
        class="tw-col-span-12 tw-border tw-bg-white tw-px-6 tw-py-8 lg:tw-col-span-7 xl:tw-col-span-8"
        style="border-radius: var(--rounded)"
      >
        <h3>{{ blog.title }}</h3>

        <div
          v-if="blog.description"
          style="
            padding: 20px;
            color: var(--c-text-black);
            background-color: var(--c-section_primary);
          "
          class="tw-my-10"
        >
          <p>{{ blog.description }}</p>
        </div>

        <div class="tw-mb-10 tw-mt-10 tw-w-full">
          <NuxtImg
            :src="blog.largeImage"
            loading="lazy"
            :alt="blog.title"
            class="tw-w-full"
            :title="blog.title"
            sizes="(max-width: 479px) 92vw, (max-width: 767px) 93vw, (max-width: 1439px) 92vw, 1280px"
            :srcset="`${blog.largeImage} 500w,
                ${blog.largeImage} 800w,
                ${blog.largeImage} 1080w,
               ${blog.largeImage} 1600w,
                ${blog.largeImage} 2500w`"
          />
        </div>
        <div>
          <lazy-library-text
            :html="blog.content"
            style="font-family: var(--f-primary)"
          ></lazy-library-text>
        </div>
      </article>

      <div class="tw-col-span-12 lg:tw-col-span-5 xl:tw-col-span-4">
        <div
          class="tw-border tw-bg-white tw-px-6 tw-py-8"
          style="border-radius: var(--rounded)"
        >
          <library-form
            v-if="form"
            :form="form"
            version="v2"
            :component="component"
          ></library-form>
        </div>
        <template v-if="sidebarComponents && sidebarComponents.length">
          <available-components-for-sidebar
            key="blog-detail-sidebar"
            key-prefix="blog-detail-sidebar"
            :specific-components="sidebarComponents"
          />
        </template>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import type { Component } from '~/types';
import { ComponentMixin } from '~/mixins/component.mixin';
import { BlogMixin } from '~/units/blogs/mixins/blog.mixin';
import { useBlogsStore } from '~/units/blogs/store';
import { ROUTES } from '~/constants/ui.constants';
import AvailableComponentsForSidebar from '~/components/theme5/AvailableComponentsForSidebar.vue';
import { mapState } from 'pinia';
import { useWebsiteStore } from '~/units/website/store';

export default defineNuxtComponent({
  name: 'Theme5BlogDetail',

  mixins: [ComponentMixin, BlogMixin],

  components: { AvailableComponentsForSidebar },

  data() {
    return {
      form: null,
    };
  },

  created() {
    this.form = this.parseForm('blog-form');
  },

  async setup(props: { component: Component }) {
    const blogsStore = useBlogsStore();
    await blogsStore.fetchSingleBlog(props.component);
  },

  computed: {
    ...mapState(useWebsiteStore, ['sidebarComponents']),

    ROUTES() {
      return ROUTES;
    },
  },
});
</script>

<style scoped></style>
